<template>
    <div class="tabs-form-container">
        <div class="tabs-item" :class="{activeTabs : typePage == 1}" @click="openTab(1)">
            Я — активний партієць
        </div>
        <div class="tabs-item" :class="{activeTabs : typePage == 2}" @click="openTab(2)" v-if="false">
            Я — волонтер
        </div>
        <div class="tabs-item" :class="{activeTabs : typePage == 3}" @click="openTab(3)">
            Я — медійно активний
        </div>
        <div class="tabs-item" :class="{activeTabs : typePage == 4}" @click="openTab(4)">
            Я — реалізую проекти
        </div>
    </div>
</template>

<script>
export default {
    name: "TabsForm",
    data () {
        return {
            typePage: 1
        }
    },
    watch:{
        type: function (){
            this.getType()
        },
    },
    methods: {
        getType() {
            this.$emit('type', {
                type: this.typePage
            })
        },
        openTab(type){
            this.typePage = type
            if (this.$route.params.type != this.typePage){
                this.$router.push('/encouragement/' + this.typePage)
            }
        }
    },
    created() {
        if (this.$route.params){
            this.typePage = this.$route.params.type
        }

        if (this.$route.name == 'active-participant-create' || this.$route.name == 'active-participant-update'){
            this.typePage = 1
        }
        else if (this.$route.name == 'volunteer-create' || this.$route.name == 'volunteer-update'){
            this.typePage = 2
        }
        else if (this.$route.name == 'media-active-create' || this.$route.name == 'media-active-update'){
            this.typePage = 3
        }
        else if (this.$route.name == 'user-project-create' || this.$route.name == 'user-project-update'){
            this.typePage = 4
        }
    }
}
</script>

<style scoped lang="less">
    .tabs-form-container{
        background-color: white;
        width: 90%;
        margin: 80px auto 20px auto;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #f5f5f5;
        border-radius: 10px;
        font-family: @secondFontFamily;
        font-weight: 600;

        @media (max-width: 768px){
            margin-top: 40px;
            margin-bottom: 0;
        }

        .tabs-item{
            flex-basis: 33.33%;
            height: 50px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;

            @media (max-width: 992px){
                padding: 15px 0;
            }

            @media (max-width: 768px){
                font-size: 12px;
                text-align: center;
            }
        }
        .activeTabs{
            background-color: #ffc900;
            border-radius: 10px;
            box-shadow: 0 24px 24px -14px rgba(249, 198, 35, 0.35);
            background-color: #ffc900;
            color: white;

            @media (max-width: 768px){
                width: auto;
            }
        }
    }
    @media (max-width: 992px) {
      .tabs-form-container{
        flex-direction: column;
      }
      .tabs-item{
        width: 100%!important;
      }
    }
</style>
