<template>
    <div class="container">
        <CommonBlock
            title="Система заохочення"
            :visible-back-btn="true"
            :cancelBackBtnEventPassed="true"
            class="calendar-common"
            @clickBack="clickBack"
        >
            <div class="description">Ваша партійна діяльність зібрана в одному кабінеті: робіть записи щодо участі у партійних заходах, пропонуйте партійні проекти та поширюйте позицію партії у соціальних мережах. Шукайте однодумців, створюйте спільні ініціативи та долучайтеся до проектів інших. Натискайте на блоки та додавайте записи.</div>
            <div class="img" :key="imgKey">
                <img :src="encImg" alt="svg">
            </div>
        </CommonBlock>
            <div class="form-container">
                <TabsForm></TabsForm>
                <router-view></router-view>
            </div>
    </div>
</template>

<script>
import UserProject from "../components/UserProject";
import TabsForm from "../components/TabsForm";
import CommonBlock from "../components/CommonBlock";


export default {
    components:{
        UserProject,
        TabsForm,
        CommonBlock
    },
    data() {
        return {
            encImg: require(`@/assets/images/2_partiec.svg`),
            imgKey: 0
        }
    },

    watch: {
        $route(from, to) {
            this.setImg()
        }
    },

    mounted() {
        this.setImg()
    },

    methods: {
        setImg() {
            let type = +this.$route.params.type || 1
            if (type == 1) {
                this.encImg = require(`@/assets/images/2_partiec.svg`)
            }
            else if (type == 3) {
                this.encImg = require(`@/assets/images/3_media.svg`)
            }
            else if (type == 4) {
                this.encImg = require(`@/assets/images/4_projects.svg`)
            }
            this.imgKey += 1
        },
        clickBack(){
            this.$router.go(-1);
        }
    }
}
</script>

<style scoped lang="less">
.container{
    display: flex;
    @media (max-width: 992px) {
        flex-direction: column;
    }
}
.form-container{
    background-color: #f5f5f5;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow: auto;

    @media (max-width: 992px){
        width: 100%;
    }
    @media (min-width: 992px){
        max-width: 70vw;
    }
}
::v-deep{
    .common-block{
        //.title{
        //    display: none;
        //}
        .description{
            font-size: 18px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.5;
            letter-spacing: -0.13px;
            color: #fff;
            font-family: @defaultFontFamily;
            margin-bottom: 22px;
            margin-top: -20px;
        }
    }

}
@media (max-height: 992px){
    .common-block{
        min-height: 100vh !important;
    }
    ::v-deep{
        .common-block{
            min-height: 100vh !important;
        }
    }
}
</style>
